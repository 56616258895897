@charset "UTF-8";
@import url("https://fonts.googleapis.com/earlyaccess/notosansjp.css");
@import url("https://fonts.googleapis.com/earlyaccess/notosansjp.css");
.g-header {
  background: #00b3ba;
  height: 80px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  position: relative;
}

.g-header__logo {
  margin-left: 30px;
  line-height: 80px;
  color: #fff;
}

.g-header__logo img {
  width: 100px;
  margin-right: 10px;
}

.g-header__title {
  vertical-align: -3.5px;
}

.g-header.-mypage {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.g-header-user {
  margin-left: auto;
}

.g-header-user__dropdown {
  padding: 0 0 5px;
  list-style: none;
  position: relative;
}

.g-header-user__dropdown:hover .g-header-user__item,
.g-header-user__dropdown:hover .g-header-user__list {
  visibility: visible;
  opacity: 1;
}

.g-header-user__nav {
  min-width: 188px;
  height: 38px;
  line-height: 38px;
  font-weight: 700;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  margin: 20px 20px 5px 0;
  display: block;
  text-decoration: none;
  color: #555;
  padding: 0 35px 0 15px;
  position: relative;
}

.g-header-user__nav i {
  color: #545454;
  margin-left: 10px;
}

.g-header-user__nav:after {
  content: "";
  display: block;
  width: 9px;
  height: 9px;
  border-top: 2px solid #707070;
  border-right: 2px solid #707070;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  position: absolute;
  right: 15px;
  top: 12px;
}

.g-header-user__list {
  width: 188px;
  border-radius: 5px;
  background-color: #fff;
  -webkit-box-shadow: 3px 5px 12px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 3px 5px 12px 0 rgba(0, 0, 0, 0.16);
  list-style: none;
  padding: 10px 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  position: absolute;
  top: 43px;
  z-index: 200;
}

.g-header-user__item {
  visibility: hidden;
  opacity: 0;
}

.g-header-user__link {
  padding: 15px 25px;
  display: block;
  text-decoration: none;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.g-header-user__link:hover {
  background-color: #edf6f6;
}

.g-header-spnav {
  display: none;
}

.g-header-overlay {
  content: '';
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  z-index: 3;
}

.side-open .g-header-overlay {
  visibility: visible;
  cursor: pointer;
}

@media (max-width: 767px) {
  .g-header {
    height: 60px;
  }
  .g-header.-mypage {
    display: block;
  }
  .g-header__logo {
    width: 100px;
    margin: auto;
    line-height: 60px;
  }
  .g-header__title {
    display: none;
  }
  .g-header-spnav {
    width: 60px;
    height: 60px;
    display: block;
    z-index: 4;
    cursor: pointer;
    position: absolute;
    padding: 20px 18px 15px;
  }
  .g-header-spnav__line {
    background: #edf6f6;
    width: 25px;
    height: 3px;
    display: block;
    margin-bottom: 5px;
    border-radius: 2px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  .side-open .g-header-spnav__line:nth-of-type(1) {
    -webkit-transform: translateY(8px) rotate(-45deg);
    transform: translateY(8px) rotate(-45deg);
  }
  .side-open .g-header-spnav__line:nth-of-type(2) {
    opacity: 0;
  }
  .side-open .g-header-spnav__line:nth-of-type(3) {
    -webkit-transform: translateY(-8px) rotate(45deg);
    transform: translateY(-8px) rotate(45deg);
  }
  .g-header-user {
    position: absolute;
    top: 0;
    right: 0;
  }
  .g-header-user__nav {
    width: 60px;
    min-width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    border-radius: none;
    background-color: transparent;
    margin: 0;
    color: #555;
    padding: 0;
  }
  .g-header-user__nav:after {
    display: none;
  }
  .g-header-user__nav i {
    font-size: 23px;
    margin-left: 0;
    line-height: 60px;
    color: #edf6f6;
  }
  .g-header-user__name {
    display: none;
  }
  .g-header-user__list {
    top: 10px;
    right: 10px;
  }
}

.g-footer.-top {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #f1f1f1;
}

.g-footer.-top__inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .g-footer.-top__inner {
    display: block;
  }
}

.g-footer.-top__inner .footer-text {
  margin-top: 5px;
  margin-bottom: 5px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-size: 1.4rem;
  line-height: 1;
}

@media (max-width: 767px) {
  .g-footer.-top__inner .footer-text {
    -webkit-box-flex: 0;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
    text-align: center;
    margin-bottom: 40px;
  }
}

.g-footer.-top__inner .footer-link-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
}

@media (max-width: 767px) {
  .g-footer.-top__inner .footer-link-list {
    display: block;
  }
}

.g-footer.-top__inner .footer-link-list .list-item {
  margin-left: 20px;
  font-size: 1.4rem;
}

@media (max-width: 767px) {
  .g-footer.-top__inner .footer-link-list .list-item {
    margin-left: 0;
    text-align: center;
    margin-bottom: 20px;
  }
}

.g-footer-nav {
  border-top: 1px solid #ccc;
  padding-top: 25px;
  margin-top: 45px;
}

.g-footer-nav__list {
  list-style: none;
  margin: 0;
  padding: 0;
  float: left;
}

.g-footer-nav__item {
  display: inline-block;
  margin-right: 30px;
}

.g-footer-nav__link {
  color: #929b9c;
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 1.8;
}

.g-footer-nav__link:hover {
  color: #929b9c;
}

.g-footer-cp {
  color: #929b9c;
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 1.8;
  float: right;
}

@media (max-width: 767px) {
  .g-footer-nav__list {
    float: none;
  }
  .g-footer-nav__item {
    display: block;
    margin-right: 0;
    text-align: center;
  }
  .g-footer-cp {
    float: none;
    text-align: center;
  }
}

.g-menu,
.g-spmenu {
  width: 17%;
  background: #e2ebeb;
  padding-top: 35px;
}

.g-spmenu {
  display: none;
}

.g-menu__list,
.g-spmenu__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.g-menu__item,
.g-spmenu__item {
  display: block;
  color: #fff;
}

.g-menu__item.-current,
.g-spmenu__item.-current {
  background-color: #edf6f6;
}

.g-menu__space,
.g-spmenu__space {
  color: #999;
  padding: 15px 35px;
  display: block;
}

.g-menu__space .g-menu__icon,
.g-spmenu__space .g-menu__icon {
  background: #ddd;
}

.g-menu__link,
.g-spmenu__link {
  display: block;
  text-decoration: none;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.g-menu__link span,
.g-spmenu__link span {
  color: #555451;
  font-weight: 700;
}

.g-menu__link .g-menu__icon,
.g-spmenu__link .g-menu__icon {
  background: #929b9c;
}

.g-menu__link .g-menu__icon i,
.g-spmenu__link .g-menu__icon i {
  color: #fff;
}

.g-menu__link:hover,
.g-spmenu__link:hover {
  text-decoration: none;
  background-color: #edf6f6;
}

.g-menu__icon,
.g-spmenu__icon {
  margin-right: 15px;
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
}

@media (max-width: 991px) {
  .g-menu__link,
  .g-spmenu__link {
    padding: 0;
  }
  .g-menu__space,
  .g-spmenu__space {
    padding: 15px;
  }
}

@media (max-width: 767px) {
  .g-spmenu {
    position: fixed;
    top: 0;
    left: -280px;
    width: 280px;
    height: 100%;
    padding-top: 0;
    text-align: left;
    z-index: 1;
    display: block;
  }
  .g-menu {
    display: none;
  }
  .side-open .g-header-overlay,
  .side-open .p-mypage-container {
    -webkit-transform: translate3d(280px, 0, 0);
    transform: translate3d(280px, 0, 0);
  }
}

.p-top-wrap {
  width: 100%;
  font-family: Arial, 'Helvetica Neue', Helvetica, 'Noto Sans JP', "ヒラギノ角ゴシック W3", "Hiragino Sans", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", sans-serif;
}

.p-top-wrap .g-pagetop-btn {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
  transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
}

.p-top-wrap .g-pagetop-btn.pageTopBtn-in {
  opacity: 1;
  visibility: visible;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 100;
  background-color: #00b3ba;
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  color: #fff;
}

.p-top-wrap .g-pagetop-btn.pageTopBtn-in::after {
  content: '';
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  top: 20px;
  width: 12px;
  height: 12px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(-45deg);
}

.g-header__login {
  color: #00b3ba;
  font-size: 1.6rem;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  background: linear-gradient(#fff, #dbdbdb);
  z-index: 200;
  position: absolute;
  top: 15px;
  right: 15px;
  width: 160px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  transition: 0.2s;
}

.g-header__login:hover {
  box-shadow: none;
  color: #00b3ba;
}

@media (max-width: 767px) {
  .g-header__login {
    width: auto;
    height: 60px;
    text-align: center;
    line-height: 60px;
    background: 0 0;
    box-shadow: none;
    color: #fff;
    font-weight: 400;
    top: 0;
  }
  .g-header__login:hover {
    color: #fff;
    text-decoration: underline;
  }
}

/*ここからリニューアル用記述*/
.pc_only {
  display: block;
}

@media (max-width: 767px) {
  .pc_only {
    display: none;
  }
}

.sp_only {
  display: none;
}

@media (max-width: 767px) {
  .sp_only {
    display: block;
  }
}

.p-top-special {
  max-width: 2200px;
  background: #f3f3f3;
  text-align: center;
}

@media (max-width: 767px) {
  .p-top-special {
    background: #fff;
  }
}

.p-top-special img {
  max-width: 940px;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .p-top-special img {
    width: 100%;
  }
}

.is-hide {
  display: none;
}

.p-top-slider-wrap {
  padding: 30px 0;
  overflow: hidden;
}

@media (max-width: 767px) {
  .p-top-slider-wrap {
    padding: 20px 0;
  }
}

.p-top-slider-list {
  padding: 0;
}

.p-top-slider-list li img {
  width: 100%;
}

.p-top-slider-list .slick-slide:not(.slick-center) {
  transform: scale(0.8);
  opacity: .7;
}

@media (max-width: 767px) {
  .p-top-slider-list .slick-slide:not(.slick-center) {
    transform: scale(1);
    opacity: 1;
  }
}

.p-top-slider-list .slide-dots {
  text-align: center;
  padding: 0;
}

.p-top-slider-list .slide-dots li {
  display: inline-block;
  margin: 0 15px;
}

.p-top-slider-list .slide-dots li button {
  position: relative;
  background: none;
  border: none;
  text-indent: -9999px;
}

.p-top-slider-list .slide-dots li button:before {
  content: '●';
  color: #B5B5B5;
  font-size: 25px;
  text-indent: 0px;
  position: absolute;
  top: 0;
  left: 0;
}

.p-top-slider-list .slide-dots li.slick-active button:before {
  content: '●';
  color: #00B3BA;
}

.p-top-contents__wrap {
  max-width: 940px;
  margin: 0 auto 100px;
}

@media (max-width: 767px) {
  .p-top-contents__wrap {
    max-width: 100%;
  }
}

.p-top-contents__wrap h1 {
  text-align: center;
  padding: 40px 0;
}

@media (max-width: 767px) {
  .p-top-contents__wrap h1 {
    padding: 32px 0 15px;
  }
}

.p-top-contents__search__title {
  color: #00b3ba;
  font-size: 16px;
  font-weight: bold;
}

@media (max-width: 767px) {
  .p-top-contents__search__title {
    margin: 0 22px 15px;
    padding: 8px 0;
    border: solid 1px;
    border-radius: 21px;
    text-align: center;
    cursor: pointer;
  }
}

@media (max-width: 767px) {
  .p-top-contents__search__title:after {
    content: url(../../../phase01/images/top/arrow.png);
    display: inline-block;
    position: relative;
    top: 0;
    left: 70px;
    width: 14px;
    height: 14px;
    transition: all .3s ease;
  }
}

@media (max-width: 767px) {
  .p-top-contents__search__title.open:after {
    position: relative;
    transform: rotate(180deg);
    top: 13px;
  }
}

.p-top-contents__search__wrapper {
  display: flex;
  background: #edf6f6;
  padding: 16px;
  justify-content: center;
  border-radius: 5px;
}

@media (max-width: 767px) {
  .p-top-contents__search__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.p-top-contents__search__all {
  display: flex;
}

.p-top-contents__search__all .all-btn {
  padding: 7px 19px;
  margin: 0 10px 0;
  background: #fff;
  color: #999999;
  border: 1px solid #d8d8d8;
  border-radius: 20px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .p-top-contents__search__all .all-btn {
    padding: 4px 15px;
    margin: 5px;
  }
}

.p-top-contents__search__box {
  display: flex;
  flex-wrap: wrap;
}

.p-top-contents__search__box:last-of-type:before {
  content: '';
  display: inline-block;
  height: 25px;
  border-right: dotted 2px #d8d8d8;
  position: relative;
  top: 8px;
}

@media (max-width: 767px) {
  .p-top-contents__search__box:last-of-type:before {
    content: '';
    display: block;
    width: 90%;
    height: 10px;
    margin: 0;
    border-right: none;
    border-top: dotted 1px #d8d8d8;
  }
}

.p-top-contents__search label {
  padding: 7px 19px;
  margin: 0 10px 0;
  background: #fff;
  color: #999999;
  border: 1px solid #d8d8d8;
  border-radius: 20px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .p-top-contents__search label {
    padding: 4px 15px;
    margin: 5px;
  }
}

.p-top-contents__search .is-active {
  background: #00b3ba;
  color: #fff;
}

.p-top-contents__list {
  margin-left: 12px;
}

@media (max-width: 767px) {
  .p-top-contents__list {
    margin: 0;
    padding: 0;
  }
}

.p-top-contents__list__item dl {
  display: flex;
  margin: 40px 0 80px;
}

@media (max-width: 767px) {
  .p-top-contents__list__item dl {
    flex-direction: column;
  }
}

.p-top-contents__list__item__img {
  position: relative;
  width: 175px;
}

@media (max-width: 767px) {
  .p-top-contents__list__item__img {
    width: 110px;
    margin: 0 auto;
  }
}

.p-top-contents__list__item__img img {
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.15);
}

.p-top-contents__list__item .movieBg:before {
  content: '';
  position: absolute;
  display: block;
  background: url(../../../phase01/images/top/bg-movie.png) no-repeat;
  width: 116px;
  height: 116px;
  z-index: -2;
  left: -30%;
  top: 15%;
}

@media (max-width: 767px) {
  .p-top-contents__list__item .movieBg:before {
    background: none;
  }
}

.p-top-contents__list__item .audioBg:before {
  content: '';
  position: absolute;
  display: block;
  background: url(../../../phase01/images/top/bg-audio.png) no-repeat;
  width: 116px;
  height: 116px;
  z-index: -2;
  left: -30%;
  top: 15%;
}

@media (max-width: 767px) {
  .p-top-contents__list__item .audioBg:before {
    background: none;
  }
}

.p-top-contents__list__item .digital-bookBg:before {
  content: '';
  position: absolute;
  display: block;
  background: url(../../../phase01/images/top/bg-digital-book.png) no-repeat;
  width: 116px;
  height: 116px;
  z-index: -2;
  left: -30%;
  top: 15%;
}

@media (max-width: 767px) {
  .p-top-contents__list__item .digital-bookBg:before {
    background: none;
  }
}

.p-top-contents__list__item__right {
  width: 667px;
  margin: 0 0 0 40px;
}

@media (max-width: 767px) {
  .p-top-contents__list__item__right {
    width: 90%;
    margin: 20px auto 0;
  }
}

.p-top-contents__list__item__right .info {
  padding: 0 0 16px 0;
  border-bottom: solid 1px #dddddd;
}

.p-top-contents__list__item__right .title-text {
  color: #545454;
  font-size: 2.4rem;
  font-weight: bold;
}

@media (max-width: 767px) {
  .p-top-contents__list__item__right .title-text {
    font-size: 2rem;
  }
}

.p-top-contents__list__item__right .author {
  color: #707070;
  font-size: 1.4rem;
}

.p-top-contents__list__item__right .category {
  color: #aaa;
  margin: 10px 0 16px;
}

.p-top-contents__list__item__right .description {
  font-size: 1.4rem;
}

.p-top-contents__list__item__right .price {
  display: flex;
  margin: 20px 0 0;
  justify-content: space-between;
  align-items: center;
  /*キャンペーンタグ用*/
  /*価格改定用*/
}

@media (max-width: 767px) {
  .p-top-contents__list__item__right .price {
    flex-direction: column;
  }
}

.p-top-contents__list__item__right .price > div {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.p-top-contents__list__item__right .price .price__num {
  font-size: 20px;
  font-weight: bold;
}

.p-top-contents__list__item__right .price .price__num:before {
  display: inline-block;
  content: '税別価格';
  margin: 0 5px 0 0;
  color: #545454;
  font-size: 16px;
  font-weight: normal;
}

.p-top-contents__list__item__right .price .price__num span {
  margin: 0 0 0 4px;
  font-size: 15px;
}

.p-top-contents__list__item__right .price .campaign {
  color: #db3763;
}

.p-top-contents__list__item__right .price .campaign:after {
  content: '割引キャンペーン中';
  display: inline-block;
  font-size: 13px;
  margin: 0 0 0 8px;
  padding: 3px 5px;
  color: #fff;
  background: #db3763;
  border-radius: 5px;
}

.p-top-contents__list__item__right .price .revision .price__num {
  font-weight: normal;
  font-size: 18px;
  text-decoration: line-through;
}

.p-top-contents__list__item__right .price .revision .revision_price {
  color: #db3763;
  font-size: 20px;
  font-weight: bold;
}

@media (max-width: 767px) {
  .p-top-contents__list__item__right .price .revision .revision_price {
    font-size: 18px;
  }
}

.p-top-contents__list__item__right .price .revision .revision_price:before {
  content: "→";
  display: inline-block;
  padding: 0 6px;
}

@media (max-width: 767px) {
  .p-top-contents__list__item__right .price .revision .revision_price:before {
    padding: 0 3px;
  }
}

.p-top-contents__list__item__right .price .revision .revision_tag {
  font-size: 13px;
  margin: 0 8px 0 0;
  padding: 3px 5px;
  color: #fff;
  background: #db3763;
  border-radius: 5px;
}

@media (max-width: 767px) {
  .p-top-contents__list__item__right .price .revision .revision_tag {
    width: 100%;
    text-align: center;
    margin: 0;
  }
}

.p-top-contents__list__item__right .price .c-button__action {
  margin: 0;
}

@media (max-width: 767px) {
  .p-top-contents__list__item__right .price .c-button__action {
    width: 100%;
    margin: 10px 0 0;
    text-align: center;
  }
}
